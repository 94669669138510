import React from 'react'

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './contacto.css'

const Contacto = (props) => {
  return (
    <div className="contacto-container">
      <Helmet>
        <title>Contacto - Smarters App</title>
        <meta property="og:title" content="Contacto - Smartersapp Web 1" />
      </Helmet>
      <header data-role="Accordion" className="contacto-header">
        <img
          alt="logo"
          src="/playground_assets/recurso%209-1500h.png"
          className="contacto-image"
        />
        <div className="contacto-separator"></div>
        <nav className="contacto-nav">
          <NavigationLinks rootClassName="navigation-links-root-class-name10"></NavigationLinks>
        </nav>
        <div data-type="AccordionHeader" className="contacto-accordion-header">
          <svg viewBox="0 0 1024 1024" className="contacto-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-type="AccordionContent"
          className="contacto-accordion-content"
        >
          <div className="contacto-nav1">
            <NavigationLinks rootClassName="navigation-links-root-class-name11"></NavigationLinks>
          </div>
        </div>
      </header>
      <h1>Contacto</h1>
      <div className="contacto-container1">
        <span>Email: admin@smartersapp.net</span>
      </div>
      <div className="contacto-features">
        <div className="contacto-pricing"></div>
        <footer className="contacto-footer">
          <div className="contacto-container2">
            <img
              alt="logo"
              src="/playground_assets/recurso%209-1500h.png"
              className="contacto-image1"
            />
            <NavigationLinks rootClassName="navigation-links-root-class-name14"></NavigationLinks>
          </div>
          <div className="contacto-separator1"></div>
          <div className="contacto-container3">
            <span className="contacto-text2">
              © 2022 Smarters App. TODOS LOS DERECHOS RESERVADOS.
            </span>
            <div className="contacto-icon-group"></div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Contacto

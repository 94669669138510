import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <Link to="/empresa" className="navigation-links-navlink">
        {props.empresa}
      </Link>
      <a href="#servicios" id="servicios" className="navigation-links-link">
        {props.servicios}
      </a>
      <Link to="/contacto" className="navigation-links-navlink1">
        {props.proyectos1}
      </Link>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  rootClassName: '',
  servicios: 'Servicios',
  proyectos1: 'Contacto',
  empresa: 'Empresa',
  '/Empresa': () => {},
}

NavigationLinks.propTypes = {
  rootClassName: PropTypes.string,
  servicios: PropTypes.string,
  proyectos1: PropTypes.string,
  empresa: PropTypes.string,
  '/Empresa': PropTypes.func,
}

export default NavigationLinks

import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import Stats from '../components/stats'
import './empresa.css'

const Empresa = (props) => {
  return (
    <div className="empresa-container">
      <Helmet>
        <title>Empresa - Smarters App</title>
        <meta property="og:title" content="Empresa - Smartersapp Web 1" />
      </Helmet>
      <header data-role="Accordion" className="empresa-header">
        <Link to="/" className="empresa-navlink">
          <img
            alt="logo"
            src="/playground_assets/recurso%209-1500h.png"
            className="empresa-image"
          />
        </Link>
        <div className="empresa-separator"></div>
        <nav className="empresa-nav">
          <NavigationLinks rootClassName="navigation-links-root-class-name8"></NavigationLinks>
        </nav>
        <div data-type="AccordionHeader" className="empresa-accordion-header">
          <svg viewBox="0 0 1024 1024" className="empresa-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-type="AccordionContent" className="empresa-accordion-content">
          <div className="empresa-nav1">
            <NavigationLinks rootClassName="navigation-links-root-class-name9"></NavigationLinks>
          </div>
        </div>
      </header>
      <div className="empresa-banner">
        <h1 className="empresa-text">Smarters App</h1>
        <span className="empresa-text1">
          <span>
            En Smarters App S.R.L. trabajamos para suplir las necesidades
            tecnológicas de nuestros clientes. Nuestro objetivo principal es
            superar sus expectativas, con un servicio personalizado y con
            productos de alta calidad. Para ello, contamos con un equipo de
            profesionales con más de 15 años de experiencia en la creación de
            páginas webs, apps móviles, diseño, desarrollo y marketing.
          </span>
          <br></br>
          <br></br>
          <span>
            La clave que nos lleva a construir proyectos exitosos es el
            equilibrio entre lo mejor del diseño gráfico, el desarrollo, la
            promoción en internet y la facilidad de uso, que nos permiten
            superar la experiencia de nuestros clientes.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
      </div>
      <Stats rootClassName="stats-root-class-name"></Stats>
      <div className="empresa-features">
        <footer className="empresa-footer">
          <div className="empresa-container1">
            <Link to="/" className="empresa-navlink1">
              <img
                alt="logo"
                src="/playground_assets/recurso%209-1500h.png"
                className="empresa-image1"
              />
            </Link>
            <NavigationLinks rootClassName="navigation-links-root-class-name13"></NavigationLinks>
          </div>
          <div className="empresa-separator1"></div>
          <div className="empresa-container2">
            <span className="empresa-text7">
              © 2022 Smarters App. TODOS LOS DERECHOS RESERVADOS.
            </span>
            <div className="empresa-icon-group"></div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Empresa

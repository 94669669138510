import React from 'react'

import PropTypes from 'prop-types'

import './stats.css'

const Stats = (props) => {
  return (
    <div className={`stats-stats ${props.rootClassName} `}>
      <div className="stats-stat">
        <h1 className="stats-text">
          <span className="">150</span>
          <span className="">+</span>
        </h1>
        <span className="stats-text03">{props.text}</span>
      </div>
      <div className="stats-stat1">
        <h1 className="stats-text04">
          <span className="">369</span>
        </h1>
        <span className="stats-text06">{props.text1}</span>
      </div>
      <div className="stats-stat2">
        <h1 className="stats-text07">
          <span className="">800</span>
          <span className="">+</span>
        </h1>
        <span className="stats-text10">{props.text2}</span>
      </div>
      <div className="stats-stat3">
        <h1 className="stats-text11">
          <span className="">24/7</span>
        </h1>
        <span className="stats-text13">{props.text3}</span>
      </div>
    </div>
  )
}

Stats.defaultProps = {
  text1: 'Proyectos',
  rootClassName: '',
  text2: 'Horas',
  text: 'Cleintes',
  text3: 'Soporte',
}

Stats.propTypes = {
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
  text3: PropTypes.string,
}

export default Stats

import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import FeatureCard3 from '../components/feature-card3'
import Stats from '../components/stats'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Smarters App</title>
        <meta property="og:title" content="Smartersapp Web 1" />
      </Helmet>
      <header data-role="Accordion" className="home-header">
        <Link to="/" className="home-navlink">
          <img
            alt="logo"
            src="/playground_assets/recurso%209-1500h.png"
            className="home-image"
          />
        </Link>
        <div className="home-separator"></div>
        <nav className="home-nav">
          <NavigationLinks
            empresa="Empresa"
            servicios="Servicios"
            proyectos1="Contacto"
            rootClassName="rootClassName19"
          ></NavigationLinks>
        </nav>
        <div data-type="AccordionHeader" className="home-accordion-header">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-type="AccordionContent" className="home-accordion-content">
          <div className="home-nav1">
            <NavigationLinks rootClassName="rootClassName20"></NavigationLinks>
          </div>
        </div>
      </header>
      <div className="home-hero">
        <h1 className="home-text">Equipo Informático</h1>
        <span className="home-text1">
          <br></br>
          <span>
            Este servicio le permite a nuestros clientes, disponer del
            conocimiento y de las habilidades técnicas de profesionales
            capacitados en el área de la informática.
          </span>
        </span>
        <div className="home-btn-group"></div>
      </div>
      <div className="home-features">
        <h1 id="#servicios" className="home-text4">
          <span>Servicios</span>
          <br></br>
        </h1>
        <span className="home-text7">
          Deje todo en las manos de profesionales y saca el máximo potencial de
          su empresa. Estamos formando por equipo fuerte, sólido, integrado por
          profesionales innovadores, curiosos e inteligentes, especialistas en
          apps móviles y soporte técnico.
        </span>
        <div className="home-container1">
          <FeatureCard3
            title="Android TV"
            description="Desarrollo de aplicaciones 100% compatibles con Android TV y SmartBox Android"
            rootClassName="rootClassName"
          ></FeatureCard3>
          <FeatureCard3
            title="Amazon Fire TV"
            description="Desarro de aplicaciones enfocadas a dispositivos y televisiones con el sistema operativo Amazon Fire OS"
            rootClassName="feature-card3-root-class-name1"
          ></FeatureCard3>
          <FeatureCard3
            title="Servidores"
            description="Administración y monitoreo de servidores. Enfocado en la optimización de recursos "
            rootClassName="feature-card3-root-class-name2"
          ></FeatureCard3>
          <FeatureCard3
            title="Soporte TI"
            description="Servicio de soporte técnico para la resolución de averías y configuraciones operativas"
            rootClassName="feature-card3-root-class-name3"
          ></FeatureCard3>
        </div>
        <div className="home-pricing">
          <Stats></Stats>
        </div>
        <footer className="home-footer">
          <div className="home-container2">
            <Link to="/" className="home-navlink1">
              <img
                alt="logo"
                src="/playground_assets/recurso%209-1500h.png"
                className="home-image1"
              />
            </Link>
            <NavigationLinks rootClassName="navigation-links-root-class-name12"></NavigationLinks>
          </div>
          <div className="home-separator1"></div>
          <div className="home-container3">
            <span className="home-text8">
              © 2022 Smarters App. TODOS LOS DERECHOS RESERVADOS.
            </span>
            <div className="home-icon-group"></div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
